import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Components
import Menu from 'components/layout/Header/Menu'

const MenuWrapper = styled(motion.div)`
  background: linear-gradient(302.67deg, #63b9e9 28.88%, #f8b133 80.97%);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  & ul {
    position: relative;
    padding-left: 0;
    margin: 0;
    width: 90%;
    height: 70%;
    list-style: none;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;

    & li a {
      color: ${({ theme }) => theme.color.light};
      font-weight: 500;
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 25px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  height: 2px;
  width: 50%;
  margin: 5px auto;
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      <AnimatePresence>
        {isOpen && (
          <MenuWrapper>
            <ul>
              <NavButtonClose
                role="button"
                onClick={() => {
                  setIsOpen(!isOpen)
                  setIsScrollable(true)
                }}
              >
                <NavButtonCloseCross />
              </NavButtonClose>
              <Menu fields={fields} />
            </ul>
          </MenuWrapper>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Mobile
