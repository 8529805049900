/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Components
import NavLink, { isPartiallyActive } from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'
import Flame from 'img/active-menu.inline.svg'
import Flame2 from 'img/hover-menu.inline.svg'
// import Flame from 'components/elements/Vectors/Flame'

// Images
import Arrow from 'img/arrow.inline.svg'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Item = styled(motion.li)`
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & div > a {
    color: ${(props) => props.theme.color.dark};
    font-family: ${({ theme }) => theme.font.family.primary};

    &[aria-current] {
      font-weight: ${({ theme }) => theme.font.weight.bold};
      @media (min-width: 992px) {
        color: ${({ theme }) => theme.color.primary};
      }
    }

    &:hover {
      @media (min-width: 992px) {
        color: ${({ theme }) => theme.color.primary};
      }
    }
  }

  @media screen and (min-width: 1200px) {
    margin-left: 55px;

    & div > a {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 1199px) {
    margin-left: 40px;
    & div > a {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;
    margin: 0 auto;

    & div > a {
      font-size: 20px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const SvgWrapper = styled.div<{ open: boolean }>`
  margin-left: 5px;

  & > svg {
    transition: all 0.3s ease;
    width: 15px;
    height: 15px;
    & path {
      @media (min-width: 992px) {
        fill: #000;
      }
    }
    ${({ open }) =>
      open
        ? css`
            transform: rotate(90deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }
`

const FlameWrapper = styled(motion.div)`
  top: -2px;
  left: -25px;

  & svg {
    overflow: visible;
    width: 35px;
    height: 25px;
  }
`

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = useState(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => {
        const isPartiallyCurrent =
          typeof window !== `undefined` &&
          window.location.pathname.indexOf(l?.link?.url || '#') !== -1

        const isActive =
          isPartiallyActive({
            isPartiallyCurrent,
            location: typeof window !== `undefined` && window.location,
            href: l?.link?.url,
          })['aria-current'] === 'page'

        return (
          <Item
            onHoverStart={() => setOpen(index)}
            onHoverEnd={() => setOpen(-1)}
            onTap={open === index ? () => setOpen(-1) : () => setOpen(index)}
            key={index}
          >
            <div className="position-relative">
              {open === index && (
                <FlameWrapper
                  className="position-absolute"
                  animate={{ y: [-2, -4] }}
                  transition={{ yoyo: Infinity }}
                >
                  <Flame />
                </FlameWrapper>
              )}
              {isActive && (
                <FlameWrapper
                  className="position-absolute"
                  animate={{ y: [-2, -4] }}
                  transition={{ yoyo: Infinity }}
                >
                  <Flame2 />
                </FlameWrapper>
              )}

              <NavLink to={l?.link?.url || '/'}>{l?.link?.title}</NavLink>
              {l?.submenu && (
                <SvgWrapper open={open === index}>
                  <Arrow />
                </SvgWrapper>
              )}
            </div>
            {l?.submenu && (
              <AnimatePresence>
                {open === index && <Submenu fields={l.submenu} />}
              </AnimatePresence>
            )}
          </Item>
        )
      })}
      <div className="mx-auto mt-5 mt-lg-0">
        <div className="ms-lg-4">
          <ButtonPrimary isCustom to="#">
            <a
              href="https://www.perfectfirecontrol.nl/"
              target="_blank"
              rel="noreferrer"
              className="px-4 py-2"
            >
              Voor bedrijven
            </a>
          </ButtonPrimary>
        </div>
      </div>
    </>
  )
}

export default Menu
