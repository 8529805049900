import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'

const StyledDesktop = styled.ul`
  flex: 1;
  list-style: none;
`

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
}

const Desktop: React.FC<DesktopProps> = ({ fields }) => (
  <StyledDesktop className="d-none d-lg-flex justify-content-end align-items-center mb-0">
    <Menu fields={fields} />
  </StyledDesktop>
)

export default Desktop
