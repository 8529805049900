/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Components
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

// Images
import Arrow from 'img/arrow.inline.svg'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
}

const Item = styled(motion.li)`
  list-style: none;

  & > a {
    color: ${(props) => props.theme.color.primary};

    & > div {
      font-size: 18px;
      color: ${({ theme }) => theme.color.dark};
    }

    & svg {
      width: 12px;
      height: 12px;
      @media (min-width: 992px) {
        fill: ${({ theme }) => theme.color.dark};
      }

      & path {
        @media (min-width: 992px) {
          fill: ${({ theme }) => theme.color.dark};
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      & div {
        color: ${({ theme }) => theme.color.light};
      }
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;

  @media screen and (min-width: 992px) {
    position: absolute;
    overflow: hidden;
    padding-top: 15px;

    & > div {
      background-color: ${({ theme }) => theme.color.light};
      padding: 25px;
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 991px) {
    position: relative;
    padding-top: unset;
    width: 100%;
    border-radius: 24px;

    & > div {
      padding: 12px 15px 12px 20px;
    }
  }
`

const Submenu: React.FC<SubmenuProps> = ({ fields }) => {
  const [open, setOpen] = useState(-1)
  return (
    <StyledSubmenu
      initial={{ height: 0, filter: 'unset' }}
      animate={{
        height: 'fit-content',
        filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3))',
      }}
      exit={{ height: 0, filter: 'unset' }}
    >
      <div>
        {fields.map((f, index) => (
          <Item
            onHoverStart={() => setOpen(index)}
            onHoverEnd={() => setOpen(-1)}
            onTap={open === index ? () => setOpen(-1) : () => setOpen(index)}
          >
            <NavLink
              to={f?.link?.url || '/'}
              key={index}
              className="d-flex align-items-center"
            >
              <motion.span animate={open === index ? { x: 6 } : { x: 0 }}>
                <Arrow className="d-flex align-items-center me-2" />
              </motion.span>

              <ParseContent content={f?.link?.title} />
            </NavLink>
          </Item>
        ))}
      </div>
    </StyledSubmenu>
  )
}

export default Submenu
