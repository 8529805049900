/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import NavLink from 'components/shared/NavLink'
import Logo from 'img/logo.png'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    `}

  @media (min-width: 992px) {
    height: 97px;
  }
  @media (max-width: 991px) {
    height: 77px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink) <{ isSticky?: boolean }>`
  position: relative;
  z-index: 999;
  width: 162px;
  & > svg {
    height: 74px;
    width: 270px;
    @media (max-width: 991px) {
      height: 54px;
      width: 151px;
    }

    ${(props) =>
    props.isSticky &&
    css`
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      `}
  }

  &:hover {
    opacity: 0.9;
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  return (
    <StyledHeader isSticky={isSticky}>
      <Container className="container">
        <Brand to="/" isSticky={isSticky}>
          <img
            src={Logo}
            alt=""
            width="100%"
            height="100%"
          />
        </Brand>

        <Mobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <Desktop fields={fields} isSticky={isSticky} />
      </Container>
    </StyledHeader>
  )
}

export default Header
